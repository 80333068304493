<template>
  <el-dialog
    title="报价"
    :visible.sync="visibleDetail"
    width="600px"
    :close-on-click-modal="false"
    class="quoted-price"
  >
    <div class="form">
      <div class="form-tit">{{obj.mname}}</div>
      <ul class="form-info">
        <li>数量：<span>{{obj.mcount}}{{obj.munit}}</span></li>
        <li>产地：{{obj.maddr}}</li>
        <li>规格描述：{{obj.mspecremark}}</li>
        <li>质量要求：{{obj.mstandard}}</li> 
        <li>备注：{{obj.remark}}</li>       
      </ul>
      
      <el-form size="small">
        <!-- <el-col :span="14"> -->
          <el-form-item label="报价数量" required>
            <div class="price-module">
              <el-input type="number" placeholder="请输入报价数量" size="small" v-model="form.bjcount">
              </el-input>
              <span>{{obj.munit}}</span>
            </div>
          </el-form-item>
          <el-form-item label="报价价格" required>
            <div class="price-module">
              <el-input type="number" placeholder="请输入价格" size="small" v-model="form.bjprice"></el-input>
              <span>元</span>
            </div>
          </el-form-item>
          <el-form-item label="多少天交货" required>
            <div class="price-module">
              <el-input type="number" placeholder="请输入天数" size="small" v-model="form.bjshdays"></el-input>
              <span>天</span>
            </div>
          </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col :span="10"> -->
          <el-form-item label="是否含票">
            <el-radio v-model="form.sfhp" :label="1">是</el-radio>
            <el-radio v-model="form.sfhp" :label="2">否</el-radio>
          </el-form-item>
          <!-- <el-form-item label="报价价格" required>
            <div class="price-module">
              <el-input placeholder="请输入价格" size="small" v-model="form.bjprice"></el-input>
            </div>
          </el-form-item> -->
          <el-form-item label="报价备注">
            <el-input v-model="form.bjremark" type="textarea" placeholder="请描述报价备注"></el-input>
          </el-form-item>
        <!-- </el-col> -->
      </el-form>
    </div>
    <div slot="footer" class="flex-jac">
      <el-button size="small" @click="visibleDetail = false">取消</el-button>
      <el-button size="small" type="primary" @click="addInqueryMU">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { addInqueryMU , getInqueryMU } from '@/api/bid'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      // type: String,
      default: '',
    },
    pid: {
      // type: String,
      default: '',
    },
    obj: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        mname:'',
        maddr:'',
        mcount:'',
        munit:'',
        mstandard:'',
        mspecremark:''
      }
    }
  },
  computed: {
    visibleDetail: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },

//   watch: {
//     visible(val) {
//       if (val) {
//       }
//     },
//   },
  name:'quotedPrice',
  data() {
    return {
      form:{
        bjcount:0,
        bjprice:0,
        sfhp: 1,
        shdays: 0,
        bjremark:'',
        bjshdays: 10
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[]   
    };
  },
  methods: {
      handleCommand(val){
        console.log(val,'val')
        this.form.unit = `元/${val}`
      },
      handleRemove(file, fileList) {
        // console.log(file, 'ppppppppppppppp',fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      addInqueryMU(){
				let bjcount = this.form.bjcount;
				let bjprice = this.form.bjprice;
				let bjremark = this.form.bjremark;
				let bjsfhp = this.form.sfhp;
				let bjshdays = this.form.bjshdays;
				//console.log(bjcount+'::::'+bjprice);
				if(bjcount === 0){
          this.$message.warning('请填写报价数量')
					return;
				}
				if(bjprice === 0){
          this.$message.warning('请填写报价')
					return;
				}
				if(bjsfhp === "" || bjsfhp === null || bjsfhp === undefined){
          this.$message.warning('请选择价格是否含票')
					return;
				}
				const that = this;
        addInqueryMU({
              iid: that.pid,
              imid: that.id,
              price: bjprice,
              count: bjcount,
              remark: bjremark,
              sfhp: bjsfhp,
              shdays: bjshdays
            }).then(res=>{
                //console.log(res);
                if (res.code === 200) {
                  this.$message.success(res.msg)
                  this.$emit('closeq')
                  //this.$emit('getInqueryMaterials')
                  // if(res.msg === "n2o"){
                  // }
                  //this.$api.msg('保存成功');
                  //this.isMask=false;
                } else {
                  //this.$api.msg(res.msg);
                }
            })
			},
      getInqueryMU(iid, imid) {
        const that = this;
        getInqueryMU({
              iid: iid,
              imid: imid
            }).then(res=>{
              if(res.code == 200){
                that.form.bjcount = res.data.count!=null?res.data.count:0;
                that.form.bjprice = res.data.price!=null?res.data.price:0;
                that.form.bjremark = res.data.remark!=null?res.data.remark:"";
                that.form.sfhp = res.data.sfhp!=null?res.data.sfhp:1;
                that.form.bjshdays = res.data.shdays!=null?res.data.shdays:10;
                that.$emit("openq")
              }
              if(res.code == 301){
                // that.$message.warning("请先登录")
                  this.$alert('请先登录', '提示', {
                    confirmButtonText: '确定',
                    // callback: action => {
                    //   this.$message({
                    //     type: 'info',
                    //     message: `action: ${ action }`
                    //   });
                    // }
                });
                
              }
            })
      }
  },
};
</script>

<style lang="less" scoped>
.quoted-price{
  .form{
    padding: 0 20px;
    &-tit{
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 20px;
    }
    &-info{
      margin: 20px 0 ;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #eee;
      li{
        width: 50%;
        margin-bottom: 18px;
      }
    }
    // .upload-style{
    //   width: 50px;
    //   height: 50px;
    // }
    /deep/ .el-upload--picture-card{
      background-color:  #fbfdff;
      border: 1px dashed  #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      cursor: pointer;
      line-height: 80px;
      vertical-align: top;
    }
    .el-upload--picture-card i{
      font-size: 24px;
      color: #8c939d;
    }
  }
  .flex-jac{
    text-align: center;
  }
  .price-module{
    display: flex;
    .el-input{
      width:93%;
    }
     /deep/ .el-input__inner{
            border: none;
            border-bottom: 1px solid #DCDFE6;
            border-radius:0;
        }
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>
