<template>
    <Wrapper>
        <div class="service">
            <DetailBanner titleType='2' title=""></DetailBanner>
            <water-title Chinese="招采投标" English="CORPORATE BUSINESS"></water-title>

            <div class="w">
                <div class="bid-detail">
                    <div class="back_icons" @click="toBack()">
                        <i class="el-icon-back"></i>
                    </div>
                    <div class="bgan" @click="showBaojia">
                        <img src="@/assets/imgs/plbj.png" alt="" />
                    </div>
                    <div class="contents">
                        <div class="companyName" >
                            <h1 style="text-align: center;">{{ caiMain.conpanyname }}</h1>
                            <div v-html="caiMain.content" style="margin-top: 10px"></div>
                            <div class="fujian" v-if="caiMain.file">
                                <a :href="infoUrl + caiMain.file" download target="_blank"
                                ><img src="@/assets/imgs/fujian.svg" alt="" />{{
                                    caiMain.filename
                                    }}</a
                                >
                            </div>
                        </div>
                        <div class="breedList">
                            <div class="breedMess" v-for="(item, index) in caigouList" :key="index">
                                <el-row :gutter="20" class="rows">
                                    <el-col :span="6"
                                    >品名：<span style="color: #1b86fb">{{
                            item.materialname ? item.materialname : ''
                          }}</span></el-col
                                    >
                                    <el-col :span="6"
                                    >规格：<span>{{
                            item.specremark ? item.specremark : ''
                          }}</span></el-col
                                    >
                                    <el-col :span="6"
                                    >数量：<span
                                    >{{ item.count ? item.count : ''
                            }}{{ item.unit ? item.unit : '' }}</span
                                    ></el-col
                                    >
                                    <el-col :span="6"
                                    >产地：<span>{{ item.addr ? item.addr : '' }}</span></el-col
                                    >
                                </el-row>
                                <el-row :gutter="20" class="rows">
                                    <el-col
                                    >质量标准：<span>{{
                            item.standard ? item.standard : ''
                          }}</span></el-col
                                    >
                                </el-row>
                            </div>
                        </div>
                    </div>
                    <quoted-price
                      :id="obj.inquirymid"
                      ref="quotedprice"
                      :obj="obj"
                      :pid="obj.inquiryid"
                      :visible.sync="visiblePrice"
                      @closeq="closeq"
                      @openq="openq"
                    ></quoted-price>
                     <!--报价组件-->
                    <Baojia
                      :id="this.currentId"
                      :secondBidFlag="secondBidFlag"
                      ref="showBaojia"
                      :visible.sync="visibleBaojiaDlg"
                      style="margin-top: 40px"
                      @closeBj="closeDetail"
                      @openBj="openDetail"
                    ></Baojia>
                </div>
            </div>
        </div>
    </Wrapper>
</template>

<script>
import { getBatchAdd,checkuserPaystatus, getDetailPrice,doadd } from '@/api/modules/bid'
import DetailBanner from '@/views/sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import Wrapper from '@/components/Wrapper.vue'
import quotedPrice from './quotedPrice.vue'
import { getUserInfo, logOff } from '@/api/modules'
import { INFO_URL } from '@/config/globalConfig'
import { QR_URL } from '@/config/globalConfig'
import Baojia from './baojia.vue'
import {
    getDetailData,
    getInqueryMaterialMain,
    uploadDeposit,
    uploadSample,
    userBidInfo,
    getBzjData,
} from '@/api/modules/bid'
export default {
    name: "zbDetailBaoJia",
    components: { quotedPrice, Baojia,DetailBanner,WaterTitle,Wrapper },

    data() {
        return {
            Visible: false,
            infoUrl: INFO_URL,
            qrurl: QR_URL,
            currentId: 0,
            obj: {},
            urls: [],
            visiblePrice: false,
            tableData: [],
            caigouList: [],
            caiMain: {},
            baojiazu: [],
            visibleBaojiaDlg: false,
            form: {
                bjcount: 50,
                sfkp: 1,
            },
            dialogVisible: false,
            itemData: {},
            depositPic: '',
            samplePic: [],
            secondBidFlag: '',
            value1: false,
            value2: false,
            steps: '',
            id: '',
            nus: null,
            payment: [],
            samplePicList: [],
            show: false,
            expressName: '',
            sampleNo: '',
        }
    },
    computed: {
        userInfo(params) {
            return this.$store.state.userInfo
        }
    },

    created() {
        // console.info("aaaaaaaaaaaaaaa");
        this.currentId = this.$route.query.id
        this.id = this.$route.query.id
        this.getUserInfo()
        this.getDetailData()
        // this.getDetailPrice()
        this.getInqueryMaterialMain()
        // this.getPayment()
    },



    methods: {
        async showBaojia() {
            // await new Promise((resolve, reject) => {
            //     this.getUserInfo()
            //     console.log(this.userInfo)
            // // if(this.userInfo=='nothisuser'){
            // //     this.$message.warning("当前用户不存在")
            // //     return
            // // }
            // // if(this.userInfo=='loginout'){
            // //     this.$message.warning("登录状态失效")
            // //     return
            // // }
            // if (this.userInfo.checkFlag&&this.userInfo.checkFlag !== 1) {
            //     this.$message.warning("资质认证没有通过，请联系工作人员")
                
            //     return;
            // }
            // }) 
            
           
      // debugger
      let that = this
      await  new Promise((resolve, reject) => {
        this.getUserInfo()
               if(this.userInfo=='loginout'){
                this.$message.error('token无效')
                return
               }
               if(this.userInfo=='nothisuser'){
                return
               }
                if (this.userInfo.checkFlag&&this.userInfo.checkFlag !== 1) {
                this.$message.warning("资质认证没有通过，请联系工作人员")
                return;
            }
        checkuserPaystatus({iid:this.id}).then(res=>{
          if(res.data==-2){
            that.$confirm('此功能需要先上传缴纳保证金凭证，是否前往?', '提示', {
              confirmButtonText: '前往',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              doadd({iid:that.id}).then(res=>{
                if(res.code==200){
                  setTimeout(() => {
                    that.$router.push({
                    path: '/bzjPay',
                    // query: { spid: this.id }
                  })
                  }, 100);
                }else{
                  that.$message.error(res.msg)
                }
              })
              
            }).catch(() => {
              that.$message({
                type: 'info',
                message: '已取消'
              });
            });
            // that.$message.warning('请先支付保证金')
            return
          }
          else if(res.data==3){
            this.$refs.showBaojia.getDetailPrice(this.currentId)
            this.visibleBaojiaDlg = true
        }else{
            that.$confirm('您的保证金凭证目前在流转/待上传，是否前往查看?', '提示', {
              confirmButtonText: '前往',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // doadd({iid:that.id}).then(res=>{
                // if(res.code==200){
                  setTimeout(() => {
                    that.$router.push({
                    path: '/bzjPay',
                    // query: { spid: this.id }
                  })
                  }, 100);
                // }
                // else{
                //   that.$message.error(res.msg)
                // }
              // })
              
            }).catch(() => {
              that.$message({
                type: 'info',
                message: '已取消'
              });
            });
          }
        })
      })


    },
        // showBaojia() {
        //     console.info("111111111");
        //     if (this.userInfo.checkFlag !== 1) {
        //         this.$message.warning("资质认证没有通过，请联系工作人员")
        //         return;
        //     }
        //     this.$refs.showBaojia.getDetailPrice(this.currentId)
        //     this.visibleBaojiaDlg = true
        // },
         // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                // this.$store.commit('userInfo', res.data || {})
                if (res.code == 200) {
                    this.checkFlag = res.data.checkFlag
                }
            })
        },
        getInqueryMaterialMain() {
            let that = this
            getInqueryMaterialMain({
                inquiryId: that.currentId,
            }).then((res) => {
                that.caiMain = res.data
                console.log('采购详情头', res.data, that.caiMain)
                that.caiMain.deadlinedate = that.formatDate(
                res.data.deadlinedate,
                'yyyy-MM-dd'
                )
                that.id = res.data.id
                // that.getBidInfo(that.id)
                that.secondBidFlag = res.data.secondBidFlag
                that.caiMain.pay_time = that.formatDate(res.data.pay_time, 'yyyy-MM-dd')
                if (!that.compare(that.caiMain.deadlinedate)) {
                that.deadline = true
                }
            })
            },
        getDetailPrice() {
            debugger;
            const that = this
            that.samplePic = []
            that.samplePicList = []
            // that.id = id
            getDetailPrice(this.id).then((res) => {
                console.log(res, 'shuj')
                this.bj = res.data.list
                this.baseForm.bjList = res.data.list
                if(res.data.list && res.data.list.length > 0){

                    if(res.data.list[0].certPicList && res.data.list[0].certPicList.length > 0){
                        res.data.list[0].certPicList.forEach(item =>{
                            that.samplePic.push(that.infoUrl + item);
                            that.samplePicList.push(item);

                        })
                    }
                    console.log( this.samplePic, 'samplePic')
                    console.log( this.samplePicList, 'samplePicList')
                }
                this.bidStatus = res.data.bidStatus
                this.secondFlag = res.data.secondBidFlag

            })
        },
        // 招标采购详情
        getDetailData() {
            let that = this
            getDetailData({
                inquiryId: that.currentId,
            }).then((res) => {
                //    console.log("res1")

                that.caigouList = res.data
                /*that.caigouList.updateDate = that.formatDate(
                    res.data.updateDate,
                    'yyyy-MM-dd'
                )*/
                // console.log('riqi', that.caigouList.updateDate)
                /*if (!that.compare(that.caigouList.updateDate)) {
                    that.updateDate = true
                }*/
                console.log('投标列表', that.caigouList)
                let datas = res.data
                // console.log('datas', datas)
                if (datas.pics != null) {
                    //datas.pics = datas.pics.split(",");
                    let picArr = datas.pics.split(',')
                    let r = picArr.filter(function (s) {
                        return s && s.trim()
                    })
                    datas.pics = r
                } else {
                    datas.pics = []
                }
                that.baojiazu = datas
                that.obj = datas
            })
        },
        toBack() {
            this.$router.back()
        },
        openDetail() {
            this.visibleDetailDlg = true
        },
        closeDetail() {
            // this.getBidInfo(this.id)
            this.visibleDetailDlg = false
        },
        closeq() {
            this.visibleBaojiaDlg = false
        },
        openq() {
            this.visibleBaojiaDlg = true
        },

    }
}
</script>


<style lang="less" scoped>
    @import url('../../../assets/common.less');
    \deep\.dialog {
        // height: 400px;
        position: relative;
    }

    .back_icons {
        padding: 10px 26px 0;
        font-size: 26px;
        width: 30px;
        cursor: pointer;
        &:hover {
            color: #409eff;
        }
    }

    .view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 450px;
        background-color: #ddd;
        \deep\.el-carousel-item {
            background-color: #eee;
        }
        .icons {
            position: absolute;
            top: -26px;
            right: -26px;
            border-radius: 50%;
            color: #000;
            background-color: #bbb;
            font-size: 26px;
            &:hover {
                color: #fff;
                cursor: pointer;
            }
        }
        img {
            width: 800px;
            height: 500px;
        }
        video {
            width: 800px;
            height: 500px;
        }
    }
    .bgan {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        // box-shadow: 0 0 4px 2px #40a0ff2a;
        z-index: 1000;
        position: fixed;
        top: 250px;
        right: 250px;
        cursor: pointer;
        // background-color: #fff;
        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }
    .contents {
        width: 80%;
        margin: 50px auto 30px;
        padding-bottom: 50px;
        margin-bottom: 100px;
        .companyName {
            h1 {
                font-size: 24px;
            }
            p {
                margin-top: 5px;
                color: #888;
                font-size: 14px;
            }
            .fujian {
                // width: 95%;
                margin: 20px auto 0;
                // margin-top: 20px;
                a {
                    display: inline-block;
                    line-height: 24px;
                    color: #1b86fb;
                    display: flex;
                    align-items: center;
                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 5px !important;
                        margin: auto 0;
                    }
                }
            }
        }
        .breedList {
            margin-top: 40px;
            .breedMess {
                margin: 40px 0;
                font-size: 14px;
                color: #777;
                .rows {
                    margin: 15px 0;
                }
                span {
                    color: #333;
                }
            }
        }
        .fyq {
            width: 70%;
            margin: 20px auto;
        }
    }
    .dialog {
        padding: 0 15px;

        // height: ;
        // position: relative;
        .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .message {
            // position: absolute;
            // right: 0;
            width: 500px;

            // float: right;
            // height: 500px;
            // background-color: #eee;
            .rows {
                margin: 10px 0;

                .messageName {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    .inputs {
        margin: 0 auto;
        width: 62%;
    }
    .right {
        // display: flex;
        // flex-direction: row;
        // margin: 0 auto;
        // justify-content: center;
        .bonds:first-child {
            // margin-right: 60px;
        }
        .borders {
            border: 1px dashed #ddd;
        }
        .imgLIst {
            display: flex;
            flex-flow: wrap;
            padding: 5px 0;
            // min-height: 155px;
            .imgs {
                width: 60px;
                height: 50px;
                border: 1px dashed #ddd;
                margin: 5px;
                position: relative;
                .ics {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    // border-radius: 50%;
                    top: -10px;
                    right: -10px;
                    color: #b1b1b1;
                    // background-color: #b1b1b1;
                    font-size: 17px;
                    cursor: pointer;
                    &:hover {
                        color: #409eff;
                        // background-color: #fff;
                    }
                }
                img {
                    width: 60px;
                    height: 50px;
                }
            }
        }
        // height: 100%;
        .avatar-uploader .el-upload {
            width: 200px;
            height: 120px;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            margin-top: 10px;
            border: 1px dashed #cecece;
            // font-size: 35px;
            color: #bdbdbd;
            width: 200px;
            height: 120px;
            line-height: 120px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-content: stretch;
            position: relative;

            .icons {
                // margin: auto 0;
                position: absolute;
                display: block;
                font-weight: bold;
                font-size: 50px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .names {
                width: 100%;
                margin-top: 30px;
            }
        }
        .el-upload__text {
            // position: absolute;
            top: 0;
        }
        .avatar {
            margin-top: 20px;
            border: 1px dashed #cecece;
            width: 200px;
            height: 120px;
            display: block;
        }
    }

    .bid-detail {
        max-width: 1190px;
        margin: 20px auto 0;
        box-shadow: 0 0 10px #ddd;
        position: relative;
        .bid-list {
            .card2 {
                // margin: 10px 0;
                display: flex;
                flex-wrap: wrap;
                cursor: pointer;
                .card2-title {
                    margin: 0 0 15px;
                    width: 55%;
                    margin-top: 10px;
                    // height: 100%;
                    // align-items: center;
                    // line-height: 46px;
                    // margin-right: 460px;
                    // margin-bottom: 15px;
                }

                .material-name {
                    width: 100%;
                    font-size: 16px;
                }
            }
            .cont {
                // margin: 20px 0 !important;
                display: flex;
                flex-direction: row !important;

                width: 100% !important;
                height: 100px;
                // justify-content: space-between;
                .left {
                    width: 70%;
                    margin: auto 0;
                }
            }
            .card {
                position: relative;
                border-radius: 8px;
                // height: 120px;
                padding: 0 26px 10px;
                // margin-bottom: 20px;
                border-bottom: 1px solid #eee;

                .tops {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    // margin-top: 15px;
                    .checkNeed {
                        margin-left: 47px;
                        width: 30%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        &-item {
                            margin-left: 15px;
                            // width: ;
                        }
                    }
                }

                .text {
                    width: 100%;
                    height: 100px;
                    background-color: #ddd;

                    // display: flex;
                    // text-align: center;
                    p {
                        img {
                            width: 100px !important;
                            height: 100px !important;
                        }
                    }
                }
                .titles {
                    font-size: 18px;
                }

                &-title {
                    font-size: 24px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;

                    .l-style {
                        display: inline-block;
                        width: 2px;
                        height: 18px;
                        background: @primary;
                        margin-right: 6px;
                    }
                }

                .carousels {
                    // margin: 15px 0;
                    width: 240px;
                    margin-left: 30px;
                    position: relative;
                    .el-carousel-item {
                        border: 1px solid #ddd;
                    }
                    img {
                        width: 238px;
                        height: 138px;
                        border: 1px solid #ddd;
                    }
                    video {
                        width: 238px;
                        height: 138px;
                        border: 1px solid #ddd;
                    }
                    i {
                        z-index: 2;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 32px;
                        font-size: 28px;
                        transform: translate(-50%, -50%);
                        // border-radius: 50%;
                        background-color: rgba(97, 97, 97, 0.637);
                        color: rgb(236, 236, 236);
                    }
                }

                .bottoms {
                    margin-bottom: 15px;
                }

                &-con {
                    // position: relative;
                    margin: auto 0;
                    display: flex;
                    flex-direction: column;
                    align-content: stretch;
                    // align-items: center;
                    font-size: 14px;
                    //margin-top: 40px;
                    width: 70%;

                    // .rightBtn {
                    //   position: absolute;
                    //   float: right;
                    //   right: 50px;
                    //   top: 20px;
                    //   margin: auto 0;
                    // }

                    &-item {
                        // width: 80%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 10px 0;

                        .item1 {
                            width: 100%;
                        }

                        .item1:last-child {
                            width: 200px;
                        }

                        .item2 {
                            position: relative;

                            .btns {
                                position: absolute;
                                font-size: 16px;
                                padding: 4px 16px;
                                border: 1px solid rgb(20, 99, 158);
                                border-radius: 4px;
                                top: -10px;
                                right: 0;
                                cursor: pointer;
                                color: rgb(20, 99, 158);
                            }

                            .btns:hover {
                                background-color: rgba(20, 99, 158, 0.1);
                            }
                        }

                        .item3 {
                            color: #999;
                            cursor: pointer;
                        }
                    }
                }

                &-con-right {
                    width: 20%;
                    text-align: center;

                    &-img {
                        width: 100px;
                        height: 100px;
                        margin: 10px auto 0;
                        display: block;
                    }

                    &-value {
                        font-size: 12px;
                    }
                }

                .card-con-imgs {
                    width: 100%;
                    margin-top: 20px;

                    .con-imgs-item {
                        width: 50px;
                        height: 50px;
                        margin: 0 4px;
                    }
                }
            }

            .card1 {
                padding-top: 20px;

                &-title {
                    font-size: 18px;
                }
            }
        }

        .images-style {
            width: 10%;
            padding: 4px;
            box-sizing: border-box;
            margin-bottom: 6px;
        }

        .xh-table {
            margin-top: 20px;

            /deep/ th {
                background-color: #fafafa;
                color: #333;
            }
        }
    }

    table {
        tr {
            td {
                padding: 12px 0;
                border-bottom: 1px solid #ebeef5;
                color: #606266;
                font-size: 14px;
            }
        }

        .thtou {
            td {
                background: #fafafa;
                color: #333;
            }
        }

        tr:not(.thtou):hover td {
            background: #f5f7fa;
        }
    }

    .formMain {
        height: 400px;
        padding: 0 26px;

        .biaoli {
            li {
                display: flex;

                > div {
                    flex: 1;
                }

                line-height: 40px;
                border-bottom: 1px solid #eee;

                input {
                    border: none;
                }

                font-size: 15px;
            }

            .litou {
                font-size: 18px;
                font-weight: 600;
                border-bottom: 3px solid #eee;
            }
        }

        .card-tit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            // .titSpan {
            //   margin-right: 300px;
            // }
        }

        .tidiv {
            // position: absolute;
            // top: 0;
            // right: 0;
            // width: 100%;
            // display: block;
            width: 10%;
            text-align: center;
            font-size: 16px;
            margin-bottom: 15px;
            float: right;

            /deep/ .el-button--primary {
                padding: 10px 22px;
            }
        }
    }

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    .small {
        width: 100px;
        height: 100px;
    }

    .fuwenben {
        padding: 20px;
        margin-top: 20px;

        /deep/ p {
            text-indent: 2em !important;
            color: red !important;
        }
    }
</style>
