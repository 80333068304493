<template>
  <div>
    <el-dialog
            :close-on-click-modal="false"
            :visible.sync="visibleDetail"
            class="quoted-price"
            title="招标报价"
            width="80%"
    >
      <div id="app">
        <el-form
                ref="baseForm"
                :model="baseForm"
                :rules="rules"
                auto-complete="on"
                class="base-form"
        >
          <el-table
                  ref="table-input"
                  :cell-style="{ padding: '0px' }"
                  :data="baseForm.bjList"
                  :row-style="{ height: '20px' }"
                  class="table"
                  highlight-current-row
                  style="font-size: 10px"
                  @row-click="selectItem"
          >
            <el-table-column label="品名" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item class="all">
                  <label>{{ scope.row.mname }}</label>
                      </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="求购数量" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item class="all">
                  <label>{{ scope.row.count }}{{ scope.row.unit }} </label>
                     </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="最大供货量" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item
                        :prop="'bjList.' + scope.$index + '.maxSupplyQuantity'"
                        class="all"
                        style="display: flex"
                >
                  <el-input
                          v-model="scope.row.maxSupplyQuantity"
                          clearable
                          type="number"
                          placeholder="请输入"
                          style="width: 80%"

                  >
                  </el-input>
                  <label>{{ scope.row.unit }}</label>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="规格" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item class="all">
                  <label>{{ scope.row.standard }}</label>
                  </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="产地" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item class="all">
                  <label>{{ scope.row.addr }}</label>
                   </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="报价" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-form-item
                        :prop="'bjList.' + scope.$index + '.bjprice'"
                        :rules="rules.bjprice"
                        class="all"
                        style="display: flex"
                >
                  <el-input
                          v-model="scope.row.bjprice"
                          clearable
                          placeholder="请输入"
                          type="number"
                          style="width: 80%"
                          :disabled="bidStatus !== 7"
                          @focus="
                      $refs.baseForm.clearValidate(
                        `bjList.${scope.$index}.bjprice`
                      )
                    "
                  >
                  </el-input>
                  <label>元/{{ scope.row.unit }} </label>
                </el-form-item>
              </template>
            </el-table-column>
            <template v-if="bidStatus == 2 || (secondBidFlag == 1 && bidStatus==4)">
              <el-table-column label="二次报价" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                          :prop="'bjList.' + scope.$index + '.yjprice'"
                          :rules="rules.yjprice"
                          class="all"
                          style="display: flex"
                  >
                    <el-input
                            v-model="scope.row.yjprice"
                            clearable
                            placeholder="请输入"
                            type="number"
                            style="width: 80%"
                            :disabled="scope.row.firstAudit != 1 || bidStatus != 2"
                            @focus="
                        $refs.baseForm.clearValidate(
                          `bjList.${scope.$index}.yjprice`
                        )
                      "
                    ></el-input>
                    <label>元/{{ scope.row.unit }} </label>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>


            <template v-if="bidStatus == 4 || bidStatus == 8">
              <el-table-column label="是否中标" prop="">
                <template slot-scope="scope">
                  <div class="flex-c-a" v-if="scope.row.bidFlag === 2">
                    <!--                  <span class="add-btn" @click="addLine()">增加</span>-->
                    <span class="flex-c-a bid_success">已中标</span>
                  </div>
                  <div class="flex-c-a" v-else>
                    <!--                  <span class="add-btn" @click="addLine()">增加</span>-->
                    <span class="flex-c-a">未中标</span>
                  </div>
                </template>
              </el-table-column>
            </template>


          </el-table>
        </el-form>
        <div class="right" v-if="bidStatus == 7">
          <div v-if="sampleFlag==1">
            <div>
              样品信息
            </div>
            <el-row :gutter="20" style="margin: 10px 0">
              <el-col :span="8"
              >快递公司：<el-input
                      placeholder="请输入快递公司"
                      v-model="expressName"
              >
              </el-input
              ></el-col>
              <el-col :span="8"
              >快递单号：<el-input
                      placeholder="请输入快递单号"
                      v-model="sampleNo"
              >
              </el-input
              ></el-col>
              <el-col :span="8"
              >样品重量：<el-input
                      type="number"
                      placeholder="请输入重量"
                      v-model="sampleWeight"
              >
                <template slot="append">kg</template>
              </el-input
              ></el-col>
            </el-row>
          </div>
          <el-row :gutter="20">
            <el-col :span="6" class="bonds"
            ><span style="font-size: 14px">资料凭证</span>
              <el-upload
                      class="avatar-uploader"
                      action="#"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :http-request="handleYyzzFileUpload2"
              >
                <div class="avatar-uploader-icon first">
                  <i class="el-icon-plus icons"></i>
                </div>
              </el-upload></el-col
            >
            <el-col
                    :span="8"
                    class="imgLIst"
                    :class="{ borders: samplePic.length > 0 }"
            >
              <div
                      class="imgs"
                      v-for="(item, index) in samplePic"
                      :key="index"
                      @mouseenter="nus = index"
                      @mouseleave="nus = null"
              >
                <i
                        class="el-icon-circle-close ics"
                        v-if="nus == index"
                        @click="deleteImg(index)"
                ></i>
                <img :src="item" />
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="flex-c-a margin-top-10 btn-flex">
          <el-button
                  v-if="bidStatus === 2 || bidStatus === 7"
                  type="primary"
                  @click="getBatchAdd"
          >报价</el-button
          >
          <!--          <el-button @click="submit">提交</el-button>-->
        </div>
        <div style="color: red;text-align: center;margin-top: 20px;">
          注意：寄送样品不合格不允许二次报价。
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { getBatchAdd,checkuserPaystatus, getDetailPrice,doadd } from '@/api/modules/bid'
import { uploadFile } from '@/api/modules/upload'
import { INFO_URL } from '@/config/globalConfig'

// import { Loading } from 'element-ui'

export default {
  // props:['baojiazu','id','pid'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      default: '',
    },
    secondBidFlag: {
      default: '',
    },
  },
  computed: {
    secondFlag() {
      return this.secondBidFlag
    },
    visibleDetail: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    // this.current = this.id
    this.getDetailPrice()
  },
  data() {
    return {
      baseForm: {
        bjList: [],
      },
      current: '',
      index: 0,
      infoUrl: INFO_URL,
      rules: {
        bjprice: [
          { required: true, message: '请输入报价价格', trigger: 'blur' },
        ],
        // yjprice: [
        //   {required: true, message: "请输入议价价格", trigger: "blur"}
        // ]
      },
      nus: null,
      bj: {},
      obj: {},
      bidStatus: 0,
      sampleFlag: 0,
      expressName: '',
      sampleNo: '',
      depositPic: '',
      samplePic: [],
      samplePicList: [],
      sampleWeight:null
    }
  },

  methods: {
    // 选中某一行修改或移除
    selectItem(row, column, event) {
      this.selectedFundRow = row
      if (event.target.innerText == '删除') {
        this.removeFundBtn(this.selectedFundRow)
      }
    },
    // 删除指定行
    removeFundBtn(params) {
      this.baseForm.bjList = this.baseForm.bjList.filter((ele) => {
        var flag = false
        // 如果不一致，则保留该行
        for (const key in params) {
          if (ele[key] != params[key]) {
            flag = true
            break
          }
        }
        return flag
      })
      // 如果全部删除后没有可以点击的一行了，需要加一行空行
      if (!this.baseForm.bjList.length) {
        this.addLine()
      }
    },
    // 增加一个空行, 用于录入或显示第一行
    addLine() {
      const newLine = {
        name: '',
        age: '',
        birthday: '',
        address: '',
      }
      this.index++
      this.baseForm.bjList.push(newLine)
    },
    // 提交
    submit() {
      this.$refs.baseForm.validate((valid) => {
        if (valid) {
          this.$confirm('您确定【提交】？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            console.log('校验通过')
          })
        }
      })
    },

    deleteImg(i) {
      this.samplePic.splice(i, 1)
      this.samplePicList.splice(i, 1)
      console.log(this.samplePicList)
    },
    handleYyzzFileUpload1(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          console.log(res, 'resres')
          this.depositPic = res.path
          console.log(res, this.depositPic, 'ssssss')
        })
        .catch((exp) => {
          console.log(exp)
        })
    },
    handleYyzzFileUpload2(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          let pic = this.infoUrl + res.path
          this.samplePicList.push(res.path)
          this.samplePic.push(pic)
          console.log(this.samplePic, 'ssssss')
        })
        .catch((exp) => {
          console.log(exp)
        })
    },

    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    getDetailPrice() {
      const that = this
      that.samplePic = []
      that.samplePicList = []
      // that.id = id
      getDetailPrice(this.id).then((res) => {
        console.log(res, 'shuj')
        this.bj = res.data.list
        this.baseForm.bjList = res.data.list
        if(res.data.list && res.data.list.length > 0){

          if(res.data.list[0].certPicList && res.data.list[0].certPicList.length > 0){
            res.data.list[0].certPicList.forEach(item =>{
              that.samplePic.push(that.infoUrl + item);
              that.samplePicList.push(item);

            })
          }
          console.log( this.samplePic, 'samplePic')
          console.log( this.samplePicList, 'samplePicList')
        }
        this.bidStatus = res.data.bidStatus
        this.sampleFlag = res.data.sampleFlag
        this.secondFlag = res.data.secondBidFlag
        this.expressName = res.data.list[0].expressName
        this.sampleWeight = res.data.list[0].sampleWeight
        this.sampleNo = res.data.list[0].sampleNo

      })
    },


    async getBatchAdd() {
      // debugger
      let that = this
      await  new Promise((resolve, reject) => {
        checkuserPaystatus({iid:this.id}).then(res=>{
          if(res.data==-2){
            that.$confirm('此功能需要先上传缴纳保证金凭证，是否前往?', '提示', {
              confirmButtonText: '前往',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              doadd({iid:that.id}).then(res=>{
                if(res.code==200){
                  setTimeout(() => {
                    that.$router.push({
                    path: '/bzjPay',
                    // query: { spid: this.id }
                  })
                  }, 100);
                }else{
                  that.$message.error(res.msg)
                }
              })
              
            }).catch(() => {
              that.$message({
                type: 'info',
                message: '已取消'
              });
            });
            // that.$message.warning('请先支付保证金')
            return
          }
          else if(res.data==3){
            this.$refs.baseForm.validate((valid) => {
              if (valid) {
                const rLoading = this.openLoading()
                let dataForm = this.baseForm.bjList

                // let requestData = {}
                // requestData.id = this.id
                let bjPrice = []

                dataForm.forEach((item, index) => {
                  console.log(index)
                  let bjitem = {}
                  bjitem.imid = item.mid
                  bjitem.price = item.bjprice
                  bjitem.yjPrice = item.yjprice
                  bjitem.sfhp = item.sfhp
                  bjitem.maxSupplyQuantity = item.maxSupplyQuantity
                  bjPrice.push(bjitem)
                })

                let requestData = {
                  id: this.id,
                  list: bjPrice,
                  certPicList: this.samplePicList,
                  depositPic: this.depositPic,
                  expressName: this.expressName,
                  sampleNo: this.sampleNo,
                  sampleWeight: this.sampleWeight,
                }
                getBatchAdd(requestData)
                        .then((res) => {
                          console.log(res, '1111111')
                          if (res.code == 200) {
                            rLoading.close()
                            that.visibleDetail = false
                            if (that.bidStatus == 0) {
                              that.$emit('closeBj')
                            }
                            that.$message.success(res.msg)
                            console.log('222222222')
                          } else {
                            rLoading.close()
                            that.visibleDetail = false
                            that.$emit('closeBj')
                          }
                        })
                        .catch((err) => {
                          console.log('异常信息:', err)
                          rLoading.close()
                          that.visibleDetail = false
                          that.$message.success('批量报价异常')
                          that.$emit('closeBj')
                        })
              }
            })
          }else{
            that.$confirm('您的保证金凭证目前在流转/待上传，是否前往查看?', '提示', {
              confirmButtonText: '前往',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // doadd({iid:that.id}).then(res=>{
                // if(res.code==200){
                  setTimeout(() => {
                    that.$router.push({
                    path: '/bzjPay',
                    // query: { spid: this.id }
                  })
                  }, 100);
                // }
                // else{
                //   that.$message.error(res.msg)
                // }
              // })
              
            }).catch(() => {
              that.$message({
                type: 'info',
                message: '已取消'
              });
            });
          }
        })
      })


    },


  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
}
/deep/.el-dialog__body {
  min-height: 300px;
}
.el-input {
  text-align: left !important;
}

.right {
  margin-top: 10px;
  // display: flex;
  // flex-direction: row;
  // margin: 0 auto;
  // justify-content: center;
  .bonds:first-child {
    // margin-right: 60px;
  }
  .borders {
    border: 1px dashed #ddd;
  }
  .imgLIst {
    display: flex;
    flex-flow: wrap;
    padding: 5px 0;
    // min-height: 155px;
    .imgs {
      width: 60px;
      height: 50px;
      border: 1px dashed #ddd;
      margin: 5px;
      position: relative;
      .ics {
        position: absolute;
        width: 16px;
        height: 16px;
        // border-radius: 50%;
        top: -10px;
        right: -10px;
        color: #b1b1b1;
        // background-color: #b1b1b1;
        font-size: 17px;
        cursor: pointer;
        &:hover {
          color: #409eff;
          // background-color: #fff;
        }
      }
      img {
        width: 60px;
        height: 50px;
      }
    }
  }
  // height: 100%;
  .avatar-uploader .el-upload {
    width: 200px;
    height: 120px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    margin-top: 10px;
    border: 1px dashed #cecece;
    // font-size: 35px;
    color: #bdbdbd;
    width: 200px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    position: relative;

    .icons {
      // margin: auto 0;
      position: absolute;
      display: block;
      font-weight: bold;
      font-size: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .names {
      width: 100%;
      margin-top: 30px;
    }
  }
  .el-upload__text {
    // position: absolute;
    top: 0;
  }
  .avatar {
    margin-top: 20px;
    border: 1px dashed #cecece;
    width: 200px;
    height: 120px;
    display: block;
  }
}
.flex-c-a {
  //display: flex;
  //align-items: center;
  justify-content: space-around;
}

.margin-top-10 {
  margin-top: 10px;
}

.btn-flex {
  display: flex;
}

.base-form.el-form-item__content {
  margin-left: 0;
}

.add-btn {
  color: #4077f4;
}

.delete-btn {
  color: #f56c6c;
}

.bid_success {
  color: #de1717;
  font-style: oblique;
}
.formMain {
  height: 400px;
  padding: 0 26px;

  .biaoli {
    li {
      display: flex;
      text-align: center;

      > div {
        flex: 1;
      }

      line-height: 40px;
      border-bottom: 1px solid #eee;

      input {
        border: none;
      }

      font-size: 15px;
    }

    .litou {
      font-size: 18px;
      font-weight: 600;
      border-bottom: 3px solid #eee;
    }
  }

  .tidiv {
    text-align: center;
    font-size: 15px;
    margin-top: 30px;

    /deep/ .el-button--primary {
      padding: 14px 42px;
    }
  }
}

//
///deep/ .el-form--inline {
//  width: 100%;
//
//  /deep/ .el-form-item {
//    width: 32%;
//  }
//}
//
///deep/ .el-input {
//  .el-input__inner {
//    text-align: center;
//  }

//}
</style>
